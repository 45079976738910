@import 'utils/variables';
@import 'components/type';
@import 'normalize.css';


:root {
    --background-color: #f1ebe4;
    --color-primary: #111111;
    --color-accent: #111111;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--color-primary);
    font-family: "Be Vietnam";
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;

    header{
        nav{
            margin: 0 0.5em 1em 0.5em;
            ul{
                li{
                    a{
                        text-decoration: none;
                        font-weight: 600;
                        color: var(--color-primary);
                    }
                }
            }
        }
    }

    main{
        .home{
            margin-bottom: 3em;
            text-align: center;

            img{
                margin-bottom: 1em;
            }

            .home__text{
                margin: 0 0.5em 2em 0.5em;
                text-align: center;

                p{
                    text-align: left;
                }
            }

            .home__button{
                text-align: center;
            }
        }
    }

    }

    section{
        &.works{
            text-align: center;
            margin-bottom: 6em;

            h1{

                &.back{
                    margin: 0 0 0 0.3em;
                }

                a{
                    color: var(--color-primary);
                    font-size: 16px;
                    text-decoration: none;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

            .card__container{
                display: flex;
                flex-direction: column;
                align-items: center;

                .card__gallery {
                    position: relative;
                    overflow: hidden;
                
                    a {
                        text-decoration: none;
                        color: var(--color-primary);
                        display: block;
                        position: relative;

                        &:hover{
                            text-decoration: underline;
                        }
                    }
                
                    img {
                        height: 40vh;
                        object-fit: cover;
                        display: block;
                        transition: 0.3s ease-in-out;
                    }
                
                    a::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 40vh;
                        background-color: rgba(17, 17, 17, 0.7); 
                        opacity: 0; 
                        transition: opacity 0.2s ease-in-out;
                        z-index: 1; 
                    }
                
                    a:hover::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    ul{
        list-style: none;
    }

  h1{
    margin: 0 0 0.5em 0;
    font-weight: 900;
    font-style: italic;
    font-size: 2em;
    text-align: left;
  }

  h2{
    margin: 0 0.3em 1em 0.3em;
    font-weight: 900;
    font-style: italic;
    padding-bottom: 0.5em;
    font-size: 2em;
    text-align: left;
    border-bottom: 1px solid var(--color-primary);
  }

  h3{
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin: 0.5em 0 2em 0;
    font-size: 1.5em;
  }

  img{
    width: 95vw;
    object-fit: cover;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    text-align: center;
    border-radius: 0;
}
  
  .button{
    margin: 2em 0 0 0;
    display: inline-block;
  
        a{
            cursor: pointer;
            background: none;
            outline: none;
            box-shadow: none;
            border: 1px solid;
            padding: 1.5em 3em;
            font-family: "Be Vietnam";
            font-weight: 400;
            font-size: 16px;
            display: inline-block;
            text-decoration: none; 
            color: inherit;      
        }


        &:hover{
            background-color: var(--color-accent);
            transition: 0.2s;
            color: var(--background-color);

            a{
                color: var(--background-color);
            }
        }
}

  nav{
    border-bottom: 1px solid var(--color-primary);
    margin-bottom: 2em;

    &.mobile{
        display: block;
        height: 70px;

        .nav__links--hidden{
            display: none;
        }

        .nav__links{
            padding: 0;
            position: fixed;
            z-index: 20;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            background: var(--color-primary);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;


            li{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5em;
                font-size: 24px;
                color: var(--background-color);
            }

            &--active{
                font-weight: bold;
            }
        }

        .burger__menu{
            position: relative;
            z-index: 30;
            height: 30px;
            padding: 0;
            margin: 0;

            &--fixed{
                position: fixed;
                right: 20px;
            }

            &--hidden{
                display: none;
            }
        }
    }

    &.desktop{
        display: none;
    }

    ul{
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 20px 0 0 0;

        &.nav__links{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 3em;

            a{
                color: var(--background-color);
                text-decoration: none;
            }
        }

        li{
            height: 30px;
        }
    }
}

footer{
    font-size: 16px;
    border-top: 1px solid var(--color-primary);
    padding-top: 2em;
    margin: 0 0.5em 6em 0.5em;

    ul{
        margin: 0;
        padding: 0;

        li{
            a{
                text-decoration: none;
                color: var(--color-primary);

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        &.info__copyright{
            display: flex;
            justify-content: space-around;
            margin-bottom: 2em;
        }

        &.info__social{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
}

/*============================================================== DESKTOP ===========================================================*/

@media(min-width: 1050px){
    body{

        header{
            nav{
                margin: 0 8em 4em 8em;

                &.mobile{
                    display:none;
                }

                &.desktop{
                    display: flex;
                    justify-content: space-between;
                    padding:0.5em 0;
                    align-items: center;
                    height: 60px;

                    .nav__links{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;

                        li{
                            align-content: space-evenly;

                            a{
                                color: var(--color-tertiary);
                                text-transform: uppercase;
                                font-weight: 400;

                                &:hover{
                                    color: var(--color-primary);
                                    text-decoration: underline;
                                }
                            }
                        }

                        .nav__links--active {
                            position: relative;
                            color: var(--color-primary);
                            font-weight: 600;
                          }
                          
                          .nav__links--active::before {
                            content: '•';
                            position: absolute;
                            left: -15px;
                            color:var(--color-primary); 
                            font-size: 1.2em;  
                          }
                          
                    }
                }
            }
        }

        main{
            .home{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin-bottom: 6em;

                img{
                    width: 30vw;
                    object-fit: cover;
                    margin-bottom: 0;
                }
                .home__container{
                    display: flex;
                    flex-direction: column;
                    width: 40vw;
                }
                .home__text{
                    display: flex;
                    flex-direction: column; 
                    margin: 0;           
                }

                .home__button{
                    display: flex;
                    flex-direction: row;

                    .button{
                        margin: 2em 1em 0 0;
                    }
                }
    
            }

        }    
        
        .works{

            h1{

                &.back{
                    margin: 0 4em 0 4em;
                }
            }

            h2{
                margin: 0 4em 2em 4em;
            }
            .card__container{
                display: flex;
                flex-direction: column;
                align-items: center;

                .card__row1, .card__row2{

                    display: flex;
                    flex-direction: row;
                    gap: 2em;

                    .card__gallery{

                        a::after{
                            height: calc(90vh - 55vh); 
                        }
                        
                        img{
                            width: 25vw;
                            height: 35vh;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    footer{
        display: flex;
        justify-content: space-between;
        margin: 0 9em 6em 9em;

        .info__copyright, .info__social{
            gap: 2em;
        }
    }
}